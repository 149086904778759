import { reactive, onBeforeUnmount } from 'vue'
export default function countDown() {
  const state: any = reactive({
    count: 0,
    timer: null,
  })

  const check_count = () => {
    let Continue = localStorage.getItem('countDown')
    if (Continue) {
      start(Number(Continue))
    }
  }

  /*开始倒计时*/
  const start = (count) => {
    clear()
    state.count = count || 60
    state.timer = setInterval(() => {
      localStorage.setItem('countDown', state.count)
      state.count--
      if (state.count <= 0) {
        localStorage.removeItem('countDown')
        clear()
      }
    }, 1000)
  }

  /*清除倒计时*/
  const clear = () => {
    if (state.timer) {
      clearInterval(state.timer)
    }
  }

  onBeforeUnmount(() => {
    clear()
  })
  return {
    state,
    start,
    check_count,
  }
}
